<template>
  <div>
    <Alert variant="red" icon="exclamation" v-if="errorMessage">{{ errorMessage }}</Alert>
    <b-table
      show-empty
      id="table"
      class="orders-table"
      striped
      sort-icon-left
      :fields="table.fields"
      :per-page="table.perPage"
      :current-page="table.currentPage"
      :items="items"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
    >
      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #empty>
        <div class="text-center">There are no records to show.</div>
      </template>
      <template v-slot:cell(createdAt)="row">
        <span
          v-b-tooltip.hover
          :title="
            formatDateTime(parseISO(row.item.createdAt), {
              format: 'dateTime',
              timeZone: sessionUserTimeZone,
              displayTimeZone: true
            })
          "
          class="dashline"
        >
          {{ formatDateTime(parseISO(row.item.createdAt)) }}
        </span>
      </template>
      <template v-slot:cell(printed)="data">
        <TicketPrintedToggle
          v-if="canPrintTickets"
          v-model="data.item.printed"
          :ticketId="data.item.id"
          @error="ticketToggleErrored"
        />
        <div v-else>
          <b-badge variant="secondary" v-if="data.item.printed === false">Not Printed</b-badge>
          <b-badge variant="success" v-if="data.item.printed === true">Printed</b-badge>
        </div>
      </template>
    </b-table>
    <b-tfoot>
      <b-tr>
        <b-td colspan="7" class="text-right">
          Total Rows: <strong> {{ table.rows }} </strong>
        </b-td>
      </b-tr>
    </b-tfoot>
    <b-pagination
      v-model="table.currentPage"
      :total-rows="table.rows"
      :per-page="table.perPage"
      aria-controls="table"
      pills
      align="center"
      first-text="First"
      prev-text="<"
      next-text=">"
      last-text="Last"
    ></b-pagination>
  </div>
</template>

<script>
import TicketPrintedToggle from '@/components/TicketPrintedToggle.vue';
import TicketServiceV2 from '@/lib/ticket-service-v2';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';
import Alert from '@/components/ui/Alert';

export default {
  props: { filter: Object },
  components: {
    Alert,
    TicketPrintedToggle
  },
  watch: {
    'form.search': function () {
      this.refreshTable();
    },
    'form.hidePrinted': function () {
      this.refreshTable();
    }
  },
  data() {
    return {
      errorMessage: null,
      form: {
        search: '',
        hidePrinted: false
      },
      table: {
        isBusy: false,
        fields: [
          { key: 'createdAt', label: 'Created' },
          { key: 'series', label: 'Series' },
          {
            key: 'ticketNumber',
            label: 'Ticket Number',
            sortable: false,
            formatter: (val) => {
              return this.formatUuid(val);
            }
          },
          {
            key: 'subTicket',
            label: 'Sub Ticket Number',
            sortable: false,
            formatter: (val) => {
              return val ? this.formatUuid(val) : '-';
            }
          },
          {
            key: 'orderId',
            label: 'Order ID',
            sortable: false,
            formatter: (val) => {
              return val ? this.formatUuid(val) : '-';
            }
          }
        ],
        rows: 0,
        currentPage: 1,
        perPage: 10,
        sortBy: 'createdAt',
        sortDesc: true
      },
      canPrintTickets: false
    };
  },
  async mounted() {
    this.canPrintTickets = await featureEnabled(LaunchDarklyKeys.PrintTickets);
  },
  computed: {
    sortDir() {
      return this.table.sortDesc === true ? 'desc' : 'asc';
    }
  },
  methods: {
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'table');
    },
    ticketToggleErrored(error) {
      this.errorMessage = this.parseError(error).message;
    },
    async items() {
      this.table.isBusy = true;

      const params = {
        orderEmail: this.filter.orderEmail || undefined,
        customerId: this.filter.customerId || undefined,
        orderId: this.filter.orderId || undefined,
        page: this.table.currentPage - 1 || 0,
        pageSize: 10,
        sortBy: this.table.sortBy || undefined,
        sortDir: this.sortDir
      };

      try {
        const result = await TicketServiceV2.listTickets(params);
        this.table.rows = result.pagination.total;

        //this.table.page = result.pagination.page + 1;

        return result.data;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
        return [];
      } finally {
        this.table.isBusy = false;
      }
    }
  }
};
</script>

<style scoped>
.dashline {
  border-bottom: 1px dashed;
}
</style>
