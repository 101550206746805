<template>
  <!-- please there is a comment underneath to confirm if I should remove it  -->
  <div>
    <Alert variant="red" icon="exclamation" v-if="errorMessage">{{ errorMessage }}</Alert>
    <b-table
      show-empty
      id="table"
      class="orders-table"
      striped
      sort-icon-left
      :fields="table.fields"
      :per-page="table.perPage"
      :current-page="table.currentPage"
      :items="items"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
    >
      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-slot:cell(createdAt)="row">
        <span
          v-b-tooltip.hover
          :title="
            formatDateTime(parseISO(row.item.createdAt), {
              format: 'dateTime',
              timeZone: sessionUserTimeZone,
              displayTimeZone: true
            })
          "
          class="dashline"
        >
          {{ formatDateTime(parseISO(row.item.createdAt)) }}
        </span>
      </template>
      <template v-slot:cell(eventDrawDate)="row">
        <span
          v-b-tooltip.hover
          :title="
            formatDateTime(parseISO(row.item.eventDrawDate), {
              format: 'dateTime',
              timeZone: sessionUserTimeZone,
              displayTimeZone: true
            })
          "
          class="dashline"
        >
          {{ formatDateTime(parseISO(row.item.eventDrawDate)) }}
        </span>
      </template>
      <template v-slot:cell(eventName)="row">
        <router-link :to="{ name: 'View Raffle', query: { id: row.item.eventId } }" v-slot="{ href }">
          <a :href="href">{{ row.item.eventName }}</a>
        </router-link>
      </template>
      <template v-slot:cell(status)="data">
        <b-badge variant="success" v-if="data.value === 'ACTIVE'">Active</b-badge>
        <b-badge variant="secondary" v-else-if="data.value === 'INACTIVE'">Inactive</b-badge>
        <b-badge variant="light" v-else-if="data.value === 'PENDING'">Pending</b-badge>
        <b-badge variant="dark" v-else-if="data.value === 'VOID'">Void</b-badge>
        <b-badge variant="danger" v-else-if="data.value === 'FAILED'">Error</b-badge>
      </template>
    </b-table>
    <b-tfoot>
      <b-tr>
        <b-td colspan="7" class="text-right">
          Total Rows: <strong> {{ table.rows }} </strong>
        </b-td>
      </b-tr>
    </b-tfoot>
    <b-pagination
      v-model="table.currentPage"
      :total-rows="table.rows"
      :per-page="table.perPage"
      aria-controls="table"
      pills
      align="center"
      first-text="First"
      prev-text="<"
      next-text=">"
      last-text="Last"
    ></b-pagination>
  </div>
</template>
<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import OrderServiceV2 from '@/lib/order-service-v2';
import Alert from '@/components/ui/Alert';

export default {
  props: ['customer'],
  components: {
    Alert
  },
  data() {
    return {
      errorMessage: null,
      form: {
        input: '',
        filter: {
          status: null,
          event: null,
          startDateTime: null,
          endDateTime: null,
          station: null,
          device: null
        }
      },
      table: {
        isBusy: false,
        fields: [
          {
            key: 'createdAt',
            label: 'Date',
            sortable: true,
            formatter: (val) => {
              return this.formatDateTime(this.parseISO(val));
            }
          },
          {
            key: 'id',
            label: 'Order ID',
            sortable: false,
            formatter: (value) => {
              return this.formatUuid(value);
            }
          },
          {
            key: 'subscriptionId',
            label: 'Sub ID',
            sortable: false,
            formatter: (value) => {
              return this.formatUuid(value);
            }
          },
          { key: 'name', label: 'Name', sortable: true },
          {
            key: 'eventName',
            label: 'Raffle Name',
            sortable: true
          },
          {
            key: 'eventDrawDate',
            label: 'Draw Date',
            sortable: true
          },
          {
            key: 'drawNum',
            label: 'Draw Number',
            sortable: true
          },
          { key: 'status', label: 'Status', sortable: true },
          { key: 'numTickets', label: 'Tickets', sortable: false, thClass: 'text-right', tdClass: 'text-right' },
          {
            key: 'amountPaidCents',
            label: 'Amount',
            sortable: false,
            thClass: 'text-right',
            tdClass: 'text-right',
            formatter: (val) => {
              return this.formatCurrency(val / 100);
            }
          }
        ],
        rows: 0,
        currentPage: 1,
        perPage: 10,
        sortBy: 'id',
        sortDesc: true
      }
    };
  },
  computed: {
    sortDir() {
      return this.table.sortDesc === true ? 'desc' : 'asc';
    }
  },
  methods: {
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'table');
    },
    async items() {
      this.table.isBusy = true;

      const sessionUser = await getAuth().sessionUser();
      const params = {
        organizationId: sessionUser.organizationUuid || undefined,
        customerId: this.customer?.id || undefined,
        stationId: this.form.filter.station?.id || undefined,
        deviceId: this.form.filter.device?.id || undefined,
        from: this.form.filter.startDateTime || undefined, // double check on from if I have to remove it from the query
        pageSize: 10,
        page: this.table.currentPage - 1 || undefined,
        sortBy: this.table.sortBy || 'id',
        sortDir: this.sortDir
      };
      try {
        const result = await OrderServiceV2.listOrders(params);
        this.table.rows = result.pagination.total;
        return result.data;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }

      this.table.isBusy = false;
    }
  }
};
</script>

<style scoped>
.dashline {
  border-bottom: 1px dashed;
}
</style>
