<template>
  <div>
    <b-container class="mb-7">
      <b-tabs pills vertical>
        <b-tab title="Details" lazy>
          <b-row>
            <b-col>
              <h1>
                <span class="text-muted">Customer ></span>
                Details
              </h1>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <Alert variant="red" icon="exclamatin" v-if="errorMessage">{{ errorMessage }}</Alert>
              <Alert variant="green" icon="check" v-if="updateSuccess">Customer updated successfully!</Alert>
            </b-col>
          </b-row>
          <b-row class="mb-5" v-if="customerReady">
            <b-col>
              <b-card class="customer-card">
                <CustomerEdit :customerId="customer.id" @onEdited="onEdited" />
              </b-card>
            </b-col>
            <b-col>
              <b-card class="tags-card">
                <AddTagForm
                  :system="false"
                  :organizationId="customer.organizationId"
                  :customerId="customer.id"
                  @onError="onError"
                  @onAddTag="onTagUpdate"
                />
                <ViewTags
                  :organizationId="customer.organizationId"
                  :customerId="customer.id"
                  :tags="customer.tags"
                  @onError="onError"
                  @onDeleteTag="onTagUpdate"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Order History" lazy>
          <b-row>
            <b-col>
              <h1>
                <span class="text-muted">Customer ></span>
                Order History
              </h1>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <OrderTable :customer="customer" />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Ticket History" lazy>
          <b-row>
            <b-col>
              <h1>
                <span class="text-muted">Customer ></span>
                Ticket History
              </h1>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <TicketsTable v-if="displayTicketsTable" :filter="filterTicketsTable" />
              <div v-else>
                <Alert variant="yellow" icon="exclamation">
                  Tickets cannot be retrieved. Customer email or id must be present.
                </Alert>
              </div>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import AddTagForm from '@/components/forms/AddTagForm';
import ViewTags from '@/components/ViewTags';
import CustomerEdit from '@/components/CustomerEdit';
import OrderTable from '@/components/OrderTable';
import TicketsTable from '@/components/TicketsTable';
import CustomerServiceV2 from '@/lib/customer-service-v2';
import Alert from '@/components/ui/Alert';

export default {
  name: 'Customer',
  components: {
    Alert,
    ViewTags,
    AddTagForm,
    CustomerEdit,
    OrderTable,
    TicketsTable
  },
  watch: {
    'table.sortBy': function () {
      this.refreshTable();
    }
  },
  data() {
    return {
      displayTicketsTable: false,
      errorMessage: null,
      customer: {},
      customerReady: false,
      updateSuccess: false,
      filterTicketsTable: null
    };
  },
  computed: {
    sortDir() {
      return this.table.sortDesc === true ? 'desc' : 'asc';
    }
  },
  async created() {
    try {
      const customerData = await CustomerServiceV2.retrieveCustomer(this.$route.query.id);

      this.customer = {
        id: customerData.id,
        firstName: customerData.firstName,
        lastName: customerData.lastName,
        organizationId: customerData.organization.id,
        address: customerData.address,
        city: customerData.city,
        postal: customerData.postalZip,
        state: customerData.provinceState,
        phone: customerData.phone,
        email: customerData.email,
        tags: customerData.tags
      };

      this.filterTicketsTable = {
        customerId: customerData.id
      };

      this.displayTicketsTable = true;

      this.customerReady = true;
    } catch (error) {
      this.errorMessage = this.parseError(error).message;
    }
  },
  methods: {
    async onTagUpdate() {
      // reload the customer
      const customerData = await CustomerServiceV2.retrieveCustomer(this.$route.query.id);

      this.customer = {
        id: customerData.id,
        firstName: customerData.firstName,
        lastName: customerData.lastName,
        organizationId: customerData.organization.id,
        address: customerData.address,
        city: customerData.city,
        postal: customerData.postalZip,
        state: customerData.provinceState,
        phone: customerData.phone,
        email: customerData.email,
        tags: customerData.tags
      };
    },
    onError(error) {
      this.errorMessage = this.parseError(error).message;
    },
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'table');
    },
    onEdited(event) {
      if (event.errorMessage) {
        this.errorMessage = event.errorMessage;
        this.updateSuccess = false;
      } else {
        this.updateSuccess = true;
        this.errorMessage = null;
      }
    }
  }
};
</script>
<style scoped>
.customer-card,
.tags-card {
  max-width: 40rem;
}
</style>
